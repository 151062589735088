<template>
    <div class="panel--main">
        <PartnerNav />


        <div class="lead-section panel-x">
            <h2 class="panel-x__title center">Converted leads</h2> 

            <div class="lead-section__filters mt-12">
                <b-input type="search" @input="searchLead" size="is-medium" icon="magnify"  placeholder="Search for..."></b-input>
            </div>
            <br><br>

          
            <div class="lead-section__table">
                <b-collapse class="ui-collapse"
                        animation="slide" v-for="(item, index) of leads" :data-sefn="item.fname" :data-seln="item.lname"
                        :key="index" :open="isOpen == index" @open="isOpen = index" :aria-id="'contentIdForA11y5-' + index">
                        <template #trigger="props">
                            <div role="button" :aria-controls="'contentIdForA11y5-' + index" :aria-expanded="props.open">
                             <div class="lead-list-main">
                                 <div class="lead-date center">
                                     <h1 class="uppercase">{{datefm(item.date)}}</h1>
                                 </div>
                                 <div class="lead-fullname">
                                     <h4>{{item.fname}}</h4> <h5>{{item.lname}}</h5>
                                 </div>
                                 <div class="lead-phone-email">
                                     <h4>{{item.phone}}</h4>
                                     <h5>{{item.email}}</h5>
                                 </div>
                                 <div class="lead-status center">
                                     <div class="icon-lead" :class="statusColor(item.status)">
                                         <i class='bx' :class="leadIcon(item.status)"></i>
                                     </div>
                                     <h5 class="uppercase">{{item.status}}</h5>
                                 </div>
                                 <div class="leaf-ref__amount">
                                     <b-tooltip type="is-light" label="Cost for lead conversion">
                                         <h3>£{{item.ref_amount}}</h3>
                                     </b-tooltip>
                                 </div>
                                 <a class="card-header-icon">
                                    <b-icon size="is-large" :icon="props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                                </a>
                             </div>
                            </div>
                        </template>
                        <div class="card-contet">
                            
                            <div class="buttons mt-5">
                                <div class="w-1-2 flex">
                                    <b-button type="is-light" disabled expanded size="is-small" @click="editLead(item)">Edit lead information</b-button>
                                    <b-button type="is-danger is-light" disabled class="ml-3" size="is-small" @click="delLead(item)">Delete</b-button>
                                </div>
                            </div>
                        </div>
                </b-collapse>
            </div>

             <empty v-show="leads.length == 0"><h1>No customers</h1></empty>

        </div>
    </div>
</template>


<script>

import PartnerNav from '@/components/PartnerNav.vue'
import Empty from '@/components/Empty.vue'

import firebase from 'firebase'
import moment from 'moment'
import { users } from '@/stores/index'
import { customSearch } from '../../utils'



export default {
    name: 'Partner',
    components: {
        PartnerNav, Empty
    },
    data: () => ({
        user: {}, form: {}, store: users(), leads: [],
        currentPage: 1, isOpen: 0, dialogLead: false,
        mode: 0, delmodal: false, clientCopy: []
      
    }),
    mounted(){
        firebase.auth().onAuthStateChanged((user) => {
        if (!user) return this.$router.push('/login')
            this.user = user
            this.init()
        })
    
    },
    methods:{
        statusColor(status){
            return status == 'lead added' ? 'is-info' : status == 'working lead' ? 'is-link' 
            : status == 'dead lead' ? 'is-danger' : 'is-success'
        },
        leadIcon(status){
            return status == 'lead added' ? 'bxs-circle-half' : status == 'working lead' ? 'bxs-circle-three-quarter' 
            : status == 'dead lead' ? 'bxs-circle' : 'bxs-party'
        },
        init(){
           this.leads = this.store.leads.filter(i => i.status == 'client')
           this.clientCopy = this.leads
        },
        datefm(d){
            return moment(d).format('D MMM')
        },
        searchLead(e){
            let clientsO = this.clientCopy;
            this.leads = customSearch(clientsO, e)

            if(!e || e == '') {
                this.leads = this.clientCopy
          }
        }

     
    },

}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/style.scss';

.lead-section__table{
   padding-bottom: 20px;
   border-radius: 20px 20px  0 0;
}

.panel--main{
    background: #fff;
}
.ui-collapse{
    padding: 20px;
    border-bottom: 1px solid #6f6f6f;
}

.lead-list-main{
    display: grid;
    grid-template-columns: 10% 25% 30% 10% 15% 5%;
    grid-gap: 10px;
    .lead-date{
        h1{
            font-weight: 600;
            max-width: 40px;
        }
    }
    .lead-status{
        h5{
            font-size: 12px;
            margin-top: 10px;
        }
    }
    .leaf-ref__amount{
        text-align: center;
        transform: translateY(20px);
        h3{
            font-size: 20px;
            font-weight: 600;
        }
    }
    .lead-date, .lead-fullname, 
    .lead-phone-email,.lead-status{
        transform: translateY(10px);
    }

}
.panel-x__title{
    font-weight: 650;
    font-size: 30px;
}
.control{
    margin-bottom: 10px;
}

.partner-stats{
    background: $bg-color;
    padding: 20px;
    margin: 50px 0;
     h2{
        font-size: 20px;
        margin-bottom: 15px;
    }
    h1{
        font-size: 28px;
        font-weight: 600;
    }
    p{
        font-size: 13px;
    }

}


.lead-section__filters{
    grid-template-columns: 65% 33%;
    margin-top: 50px;
}


.icon-lead{
    height: 30px;
    width: 30px;
    margin: 0 auto;
    border: 1px solid red;
    text-align: center;
    border-radius: 50px;
    position: relative;
    i{
        font-size: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.is-info{
     border-color: #3e8ed0;
     i{ color: #3e8ed0; }
}
.is-link{
    border-color: yellow;
    i{color: yellow;}
}
.is-danger{
    border-color: red;
    i{color: red;}
}
.is-success{
    border-color: green;
    i{color: green;}
}


</style>